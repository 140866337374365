@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.App {
	text-align: center;
}

body {
	overflow-x: auto !important;
}

input:focus::placeholder {
	color: transparent;
}
/* Overriding Bootstrap Colors */
:root {  
	--main-primary:#b4cee4;
	--main-success:#c5e1a5;
	--main-warning:#ede493;
	--main-danger:#ce8d8d;
}
  
.bg-primary {
	background-color: var(--main-primary) !important;
}

.border-primary {
	border-color: var(--main-primary) !important;
}

.dropdown-item:active {
	background-color:var(--main-primary) !important;
}

.dropdown-menu.show {
    display: block;
    max-height: 300px;
    overflow: auto;
}
.bg-success {
	background-color: var(--main-success) !important;
}

.success-text {
	color: #373;
}

.border-success {
	border-color: var(--main-success) !important;
}

.bg-warning {
	background-color: var(--main-warning) !important;
}

.border-warning {
	border-color: var(--main-warning) !important;
}

.warning-text {
	color: #a39566;
}

.bg-danger {
	background-color: var(--main-danger) !important;
}

.border-danger {
	border-color: var(--main-danger) !important;
}

.table {
	border-bottom: solid 1px #dee2e6;
}

/* Custom */

.transparent-button {
	
}

.black-link{
	color:white;
}

.black-link:hover{
	color:white;
}

.my-title {
	padding-top: 20px;
    text-align: left;
	font-weight: 300;
	font-size: 4rem;
  color:#fff;
}

.my-title-centered {
	font-weight: 300;
	font-size: 2rem;
}

.my-title2 {
	padding-top: 20px;
    text-align: left;
	font-weight: 300;
	font-size: 1.7rem;
  color:#fff;
}

.my-subtitle {
    /* padding-left: 50px; */
    text-align: center;
	font-weight: 400;
	font-size: 1.8rem;
  color:#fff;
}

.my-inner-subtitle {
    padding-left: 70px;
    text-align: left;
	font-weight: 300;
	font-size: 1.8rem;
  color:#fff;
}

.my-form{
  margin-top:5%;
}

.my-hr{
	margin: 3% 5%;
}

.my-colored-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  /* margin-bottom: -2px; */
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: #aaa;
  height: 20px;
  width: 20px;  
}

.no-data{
	margin-top: 5%;
	text-align: center;
    font-weight: 200;
}

.register-button {
	width: 40%;
}

li > .my-navlink:hover {
	background-color: #dae0e5 ;
}

li > .my-navlink {
	width: 100%;
}

h4 {
	font-weight: 400;
}
#copy_button{
	margin: 0 10px;
	cursor: pointer;
}
#copy_button:hover{
	color: #6b6b6b
}

.my-spinner{
	margin-top: 5%;
	margin-bottom: 5px;

	width: 5rem;
	height: 5rem;
}

.my-little-spinner{
  margin-top: 5px;
	margin-bottom: 5px;
	width: 20px;
	height: 20px;
}


.disabled{
	pointer-events: none;
    cursor: default;
    color: rgb(177, 175, 175)
}


/* --------------------- Login Page --------------------- */

/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #fff;}
input:-moz-placeholder { color: #fff;}
input::-moz-placeholder { color: #fff;}
input:-ms-input-placeholder { color: #fff;}

textarea::-webkit-input-placeholder { color: #fff;}
textarea:-moz-placeholder { color: #fff;}
textarea::-moz-placeholder { color: #fff;}
textarea:-ms-input-placeholder { color: #fff;}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}


/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: 'Poppins', sans-serif;  
  font-size: 13px;
  color: #e5e5e5;
  line-height: 1.5;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/



.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;  
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #b224ef00;
}

.wrap-login100 {
  width: 400px;
  border-radius: 0px;
  overflow: hidden;
  padding: 55px 55px 37px 55px;

  background: #f8f9fa8a;
  
}


/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-logo {
  font-size: 60px; 
  color: #333333;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.login100-form-title {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  color: #152D4F;
  line-height: 1.2;
  text-align: center;
  text-transform: none;

  display: block;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid rgba(255,255,255,0.24);
  margin-bottom: 30px;
}

.input100 {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #495057 !important;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px 0 38px;
}

/*---------------------------------------------*/ 
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #fff;
}

.focus-input100::after {
  font-family: 'Material-Design-Iconic-Font';
  font-size: 22px;
  color: #fff;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 6px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus {
  padding-left: 5px;
}

.input100:focus + .focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.has-val.input100 {
  padding-left: 5px;
}


/*==================================================================
[ Restyle Checkbox ]*/

.contact100-form-checkbox {
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 35px;
}

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #fff;
  line-height: 1.2;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f26b";
  font-family: 'Material-Design-Iconic-Font';
  font-size: 13px;
  color: transparent;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: #fff;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: #555555;
}

.line {
    border-style:solid;
    border-width:2px;  
    border-top-color:#000000;
}
/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #08173C;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 120px;
  height: 50px;
  border-radius: 0px;

  background: #FD8200;
  position: relative;
  z-index: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-color: #FDB413;
  top: 0;
  left: 0;
  opacity: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  color: #fff;
}

.login100-form-btn:hover:before {
  opacity: 0;
}


/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 15px 37px 15px;
  }
}



/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  font-family: 'Poppins', sans-serif;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}


/* Utils */
.p-b-34 {padding-bottom: 34px;}
.p-t-27 {padding-top: 27px;}
.p-t-90 {padding-top: 20px;}
.p-t-90b {padding-top: 20px;}
