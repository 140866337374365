body {
  overflow-x: hidden;
}

a, a:hover{
  color:#ffffff;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color:#ffffff;
  z-index:9999;
}
/*** the below css are added by tom *****/
#navigation-bar .border-bottom-yellow{
  background: #0f1027 url("/header.png") center no-repeat !important;
  background-size: cover;
}
#navigation-bar .navbar-dark .navbar-toggler{
  background-color: #1d1f37;
}
#sidebar-wrapper {background-color:#1d1f37;}
.navbar {background-color:#0f1027; border-bottom:1px solid #33bcb3;}
.navbar .nav-item {background-color:#1d1f37;}
.list-group-item {background-color:#1d1f37; color:#ffffff !important; border-bottom: 1px solid #000000;}
.list-group-item > .btn {color:#ffffff  !important;}
.container-fluid {background-color:#242846;}
.list-group-item:hover, .list-group-item.active, .list-group-item-action:focus, .list-group-item-action:hover{ background-color: #33bcb3 !important;}
.navbar .nav-item.profile, .nav-item.profile-drop-down, .nav-item.profile-drop-down > .btn, .nav-item.profile-drop-down > .btn.focus, .nav-item.profile-drop-down > .btn:focus{ color:#ffffff !important; border:none !important;box-shadow:none;}
.navbar .nav-item.profile{ background-color: transparent !important;}
.nav-item.profile-drop-down{ background-color:#1d1f37 !important; border-radius: 5px;}
.generators .body{margin:0 2.5%;}
.generators .my-title{position: relative;left:2.5%;}
.container-inner-wrapper{ padding:0 2.5%;}
.container-inner-wrapper-header, .farm-header{
  background-color:#3D6188;
  width:auto;
  max-width: 400px;
  height:auto;
  padding:10px;
  color:#ffffff;
}
.container-inner-wrapper table {
  border:1px solid #2A4B6E !important;
}
.container-inner-wrapper .table td, .table th{
  border-color: #2A4B6E !important;
}
.container-inner-wrapper .table th{
  background-color: #2A4B6E !important;
}

.date-picker .btn-link, table .btn-link, .btn-link{
  color:#ffffff !important;
}
.date-picker .react-datetimerange-picker, .date-picker .react-datetimerange-picker__wrapper, .date-picker-opener{
  background-color:  #33bcb3;
  border-color: #33bcb3;
  padding:5px;
}
.date-picker *, .date-picker-opener * {
  color:#000000;
}
.date-picker-opener {
  width:287px;
  overflow: hidden;
  padding:10px;
}
.date-picker-opener .pull-left{
  float:left;
}
.date-picker-opener .pull-right{
  float:right;
  margin-right:5px;
}

.container-fluid.overflow-hidden{ overflow: hidden;}
.more-details, .more-details *, .shutdowns-site-card .card-footer, .all-site-card .card-footer, .detections-site-card .card-footer{
  color:#ffffff;
  background-color: #324B6B;
  border:0px;
}

.shutdowns-site-card .card-footer .text-muted, .all-site-card .card-footer .text-muted, .detections-site-card .card-footer .text-muted{
  color:#fff !important;
}
.more-details a:hover{
  color:#ffffff;
  text-decoration: underline;
}
.generator .card-header, .camera .card-header{
  font-size:2.50rem;
  border-bottom:0px;
  background-color: rgb(0, 0, 0, 0);
  padding-bottom:0%;
}

.card .rgb, .card .thermal, .card .active-round  {
  padding:20px;
  background: url("/circle-blue-top.png") top center no-repeat;
  width:120px;
  height:120px;
  display:table;
}
 .vertical-align{
  display:table-cell;
  vertical-align: middle;
  color:#fff;
}
.vertical-align p{
  margin:0;
  color:#fff;
}
.card .detections, .card .deterrences, .card .shutdowns{
  padding:20px;
  background: url("/circle-blue-bottom.png") top center no-repeat;
  width:120px;
  height:120px;
  display:table;
  margin: auto;
}

.generator.card{
  background: #5890AF url("/generator-inactive.png") bottom 20px center no-repeat;
  background-size: cover;
}
.generator.card:hover{
  background: #6ABBF1 url("/generator-active.png") bottom 20px center no-repeat;
  background-size: cover;
}
.camera.card{
  background: #5890AF url("/camera-inactive.png")  bottom  center no-repeat;
  background-size: cover;
}
.camera.card:hover{
  background: #6ABBF1 url("/camera-active.png") bottom  center no-repeat;
  background-size: cover;
}

.all-site-card .card-body{
  background: #9a9a9a url("/camera-all-generators.png") center no-repeat;
}
.detections-site-card .card-body{
  background: #9a9a9a url("/camera-all-active-generators.png") center no-repeat;
}
.deterrences-site-card .card-body{
  background:#9a9a9a url("/camera-deterrences.png") center no-repeat;
}
.shutdowns-site-card .card-body{
  background:#343a40 url("/camera-all-inactive-generators.png") center no-repeat;
}
h1, h2, h3, h4, h5, h6, .table td, table th, .card-header{
  color:#fff;
}
.card-header p{
  font-size:2rem;
}
.date-picker-opener-with-title {
  display:inline-block;
  margin-left:30px;
  font-size:1rem;
}
label.control-label{
  color: #fff;
}
.camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
  padding: 0px;
}
.modalWindow .my-title-centered, .modalWindow .table *{
  color:#000;
}
.multiselect-container  .optionContainer{
  max-height:500px;
}
/*** the above css are added by tom ***/
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

@media (min-width:1200px) and (max-width: 1570px){
  .generator.card .detections, .generator.card .deterrences, .generator.card .shutdowns{
      background-size:contain;
      width:90px;
      height:100px;
      font-size: 0.75rem;
      padding:0px;
  }
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:80px;
    height:100px;
    font-size: 0.75rem;
    padding:0px;
}
.camera.card .active-round{
  background-size:contain;
  width:100px;
  height:100px;
  padding:0px;
}
.camera.card .active-round.horn{
  margin-left:-17px;
}
.camera.card .active-round.stream{
  margin-right:-17px;
}
.card-header p{
  font-size:1.5rem;
}

}
@media (min-width:1200px) and (max-width: 1400px){
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:64px;
    height:65px;
    font-size: 0.55rem;
    padding:0px;
}
}
@media (min-width:1570px) and (max-width: 1920px){
  .camera.card .detections, .camera.card .deterrences, .camera.card .shutdowns{
    background-size:contain;
    width:80px;
    height:100px;
    font-size: 0.75rem;
    padding:0px;
}
.camera.card .active-round{
  background-size:contain;
  width:100px;
  height:100px;
  padding:0px;
}
}

@media (min-width:991px) and (max-width: 1115px){
  .generator.card .detections, .generator.card .deterrences, .generator.card .shutdowns{
      background-size:contain;
      width:90px;
      height:100px;
      font-size: 0.75rem;
      padding:0px;
  }

}
@media (max-width:1600px)
{
  #navigation-bar .border-bottom-yellow{
    background-position:left -10rem bottom !important;
  }
}


@media( max-width: 768px){
  .events-table td{
    display:block;
    width:100% !important;
  }
  .table-separator-sm{
    background-color: #193146;
    height: 5px;
    display:table !important;
  }


}

@media( max-width: 768px){
  .annotationDiv{
    width:300px !important;
  }
  .annotation-ok-btn{
    width:100% !important;
  }
  .generator-stats-table td{
    display:block;
    width:100%;
  }
  .smallDeviceTitle{
    font-weight: bold;
    padding-right: 15px;
  }
  .smallDeviceAdjustment{
    margin-bottom:20px;
  }
}

@media (max-height:570px){
  
  .annotationDiv hr, .annotationDiv .form-group{
    margin-top:0 !important;
    margin-bottom: 0 !important;
  }
}
.box-deterrence, .box-deterrence-arrow{
  background-color: #75738A !important;
}
.box-detection, .box-detection-arrow{
  background-color: #BAAF6D !important;
}
.box-shutdown,.box-shutdown-arrow{
  background-color: #653941 !important;
}
.box-deterrence-arrow, .box-detection-arrow, .box-shutdown-arrow{
  text-transform: capitalize;
}
/* .event-detail-box td.box-deterrence-arrow:after, 
.events-detail-box td.box-detection-arrow:after, 
.events-detail-box td.box-shutdown-arrow:after
 {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-top: 20px solid transparent;
	border-bottom: 20px solid transparent;
}
.events-detail-box td.box-deterrence-arrow:before,
.events-detail-box td.box-detection-arrow:before,
.events-detail-box td.box-shutdown-arrow:before
{
	content: "";
	position: absolute;
	right: -20px;
	bottom: 0;
	width: 0;
	height: 0;
	border-left: 20px solid #2e90cb;
	border-top: 28px solid transparent;
	border-bottom: 20px solid transparent;
} */

/**
added by thomas for the new design IRIDA **/
.events-table{
  border:0px !important;
}
.events-table td.gradiant-btn{
	width: 200px;
	position: relative;
	background: #2e90cb;
	background-image: linear-gradient(90deg, #8acba6, #2e90cb);
	left: -35px;
  border:0px;
  border-bottom:10px solid transparent;
  border-top:0px;
  padding-left:25px;
  

}

.events-table td.gradiant-btn:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-top: 20px solid transparent;
	border-bottom: 20px solid transparent;
}
.events-table td.gradiant-btn:before {
	content: "";
	position: absolute;
	right: -20px;
	bottom: 0;
	width: 0;
	height: 0;
	border-left: 20px solid #2e90cb;
	border-top: 28px solid transparent;
	border-bottom: 20px solid transparent;
}
.event-detail-box{
  border-collapse: separate;
	border-spacing: 0;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
 border-radius:10px; background-color: #2a314b; /* For browsers that do not support gradients */
  padding:10px;
  border:1px solid #6d77a1 !important;
}
.event-details-info{
  width:50%; padding-left:50px; border-bottom:1px solid #6d77a1;
  border-top:0px !important;
}

.event-detail-box .title{
  font-weight: bold;
  border:0px !important;
}
.event-detail-box .gradiant-btn.title{
  left:0px !important;
}
.deleteBtn {
	background-repeat: no-repeat;
	background-size: auto;
	color: #fff;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
 border-radius:5px; 
 border:1px solid #e56632;
background-color: #1e253c; /* For browsers that do not support gradients */
	background-image: linear-gradient(-58deg, #1e253c, #3f4565);
	color: #fff;
	font-weight: 400;
    text-align: center;
    vertical-align: middle;
	padding: 0.375rem 1.85rem;
    font-size: 1rem;
    line-height: 1.5;
}
.deleteBtn:hover {background-image: linear-gradient(90deg, #ed9032, #e03933);}
.primaryBtn {
	background-repeat: no-repeat;
	background-size: auto;
	color: #fff;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
 border-radius:5px; 
 border:1px solid #61abba;
background-color: #1e253c; /* For browsers that do not support gradients */
	background-image: linear-gradient(-58deg, #1e253c, #3f4565);
	color: #fff;
	font-weight: 400;
    text-align: center;
    vertical-align: middle;
	padding: 0.375rem 1.85rem;
    font-size: 1rem;
    line-height: 1.5;
}
.primaryBtn:hover, .primaryBtn.active {background-image: linear-gradient(90deg, #8acba6, #2e90cb);}
.navBtn{
  border:1px solid #61abba;
  border-radius: 50%;
  width:50px;
  height:50px;
}

.arrow {
  border: solid #61abba;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  position:relative;
}
.navBtn:hover{
  background-color: #2e90cb;
  background-image: linear-gradient(90deg, #8acba6, #2e90cb);
}
.arrow.right{
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  left:-2px;
}
.arrow.left{
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left:2px;
}
.navBtn:hover .arrow{
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
}

/*** New design changes =by Tom ****/
.generatorName{
  font-size:4.0rem;
  vertical-align: middle;
  text-align: center;
  padding-left:15px;
}
.generatorImage img{
  vertical-align: middle;
  height:90px;
}
.generatorEventsInnerTable{
  width: 100%;
}
table.generatorEventsInnerTable{
  border:none !important;
}
.generatorEventsTable{
  border: 1px solid #6d77a1;
  /* margin-top:-10px; */
  border-radius:10px;
  border-top-right-radius: 0px;
  width: 100%;
 /*border: 1px solid #6d77a1;*/
  border-collapse: separate;
  border-spacing:0;
  background-color: #2a314b; /* For browsers that do not support gradients */
  background-image: linear-gradient(-58deg, #1e253c, #3f4565);
  color:#fff;
}
.generatorsTopTable{
  width:100%;
  color:#fff;
  border-spacing:0;
}
.generatorEventsTable td, 
.generatorEventsTable th,
.generatorsTopTable td, .generatorsTopTable th {
  padding: 0.60rem;
  vertical-align: middle;
}
.generatorsTopTable tr.detections,
.generatorsTopTable tr.deterrences,
.generatorsTopTable tr.shutdowns,
.generatorsTopTable tr.shutdowns_ghost
{
  background-color: #2a314b; /* For browsers that do not support gradients */
  background-image: linear-gradient(-58deg, #1e253c, #3f4565);
}
.generatorsTopTable tr.detections td,
.generatorsTopTable tr.deterrences td,
.generatorsTopTable tr.shutdowns td,
.generatorsTopTable tr.shutdowns-ghost td,
.generatorsTopTable td
{
  text-align: center;
  font-weight: bold;
}
.generatorsTopTable tr.detections td{
  padding-top:20px;
  border-bottom:1px solid #fff;
}
.generatorsTopTable tr.deterrences td{
  border-bottom:1px solid #fee598;
}
.generatorsTopTable tr.shutdowns td{
  border-bottom:1px solid #bf2025;
}
.generatorEventsInnerTable td{
  border:none !important;
}

.generatorsTopTable tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}
.generatorsTopTable tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
.generatorsTopTable tr.detections td:first-child{
    border-top-left-radius: 10px;
}
.generatorsTopTable tr.detections td:last-child {
    border-top-right-radius:0px;
}
.generatorsTopTable .heading-row td{
  border-radius: 0px !important;
}
  .generatorsTopTable tr.detections:hover  {
      background-color: #fff;
      color: #303456;
      background-image:none;
  }
  .generatorsTopTable tr.deterrences:hover {
      background-color: #d1c279;
      color: #303456;
      background-image:none;
  }
  .generatorsTopTable tr.shutdowns:hover {
      background-color: #bf2025;
      background-image:none;
  }
  .generatorsTopTable tr.shutdowns_ghost:hover {
      background-color: #5b2928;
      background-image:none;
  }
.generatorsTopTable, .noStyledTable {
    border:0px;
    background-color:none;
    background-image:none;
    border-spacing:0;
}
table.noStyledTable{
  border:none !important;
}
/* .noStyledTable td {padding:0;} */
.generatorsTopTable .heading-row{ position:relative;}
.generatorsTopTable .heading-row td{
  /* padding-top:20px !important;
  padding-bottom:30px !important; */
  text-align: center;
}
  .generatorsTopTable .dayCameraDownTriangle{
      display:block;width: 100%;height: 25px;background: #658c85 linear-gradient(to right, #346d98, #658c85);clip-path: polygon(0% 100%, 50% 0%, 100% 100%);position: absolute;bottom: -25px;left: 50%;transform: translate(-50%, 0);transform: translate(-50%, 0) rotate(180deg);
  }
  .generatorsTopTable .nightCameraDownTriangle{
      display:block;width: 100%;height: 25px;background: #335d92 linear-gradient(to right, #48518b, #335d92);clip-path: polygon(0% 100%, 50% 0%, 100% 100%);position: absolute;bottom: -25px;left: 50%;transform: translate(-50%, 0);transform: translate(-50%, 0) rotate(180deg);
  }
.generatorsTopTable .dayCameraHeading{
  padding:0px;
  width:40%;height: 50px;text-align:center;background: rgb(130,203,167);background: linear-gradient(90deg, rgba(130,203,167,1) 0%, rgba(7,143,199,1) 100%);border-radius:unset;
}
.generatorsTopTable .nightCameraHeading{
  width:40%;height: 50px; text-align:center;background: rgb(27,117,188);background: linear-gradient(90deg, rgba(27,117,188,1) 0%, rgba(88,95,171,1) 100%);border-radius:unset;
  padding:0px;
}
.generatorsTopTable .heading-row.daycamera{
  height: 50px;background: rgb(101,140,133);background: linear-gradient(90deg, rgba(101,140,133,1) 0%, rgba(52,109,152,1) 100%);border-radius:unset;
}
.generatorsTopTable .heading-row.nightcamera{
  height: 50px;background: rgb(51,93,146);background: linear-gradient(90deg, rgba(51,93,146,1) 0%, rgba(72,81,139,1) 100%);border-radius:unset;
}
.text-center{
  text-align: center;
}

.text-left{
  text-align: left !important;
}
.dayNightCameraTableSplitter{
  border-right:1px solid #6d77a1;
}
.generatorsTopTable .headersTable{
  position:relative;
  top:10px;
}


.actionsTable{
  border-radius: 10px;
  width: 100%;
  /*border: 1px solid #6d77a1;*/
   border-collapse: separate;
   border-spacing:0;
   -moz-border-radius:10px;
     -webkit-border-radius:10px;
   background-color: #2a314b; /* For browsers that do not support gradients */
   background-image: linear-gradient(-58deg, #1e253c, #3f4565);
   color:#fff;
}
.actionsTable td, .actionsTable th{
  border: 1px solid #6d77a1;
  padding: 0.75rem;
  vertical-align: middle;
  text-align: center;
    
}

.actionsTable tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.actionsTable tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.actionsTable tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
.actionsTable tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.actionsTable th.actions{
  background-color: #c5278a; /* For browsers that do not support gradients */
  background-image: linear-gradient(90deg, #c5278a, #a53b94);
}

.actionsTable th.operations{
  background-color: #a53b94; /* For browsers that do not support gradients */
  background-image: linear-gradient(90deg, #a53b94, #84469d);
}
.actionsTable th.status{
  background-color: #8acba6; /* For browsers that do not support gradients */
  background-image: linear-gradient(90deg, #8acba6, #2e90cb);
}
.actionsTable th.logs{
  background-color: #2e76be; /* For browsers that do not support gradients */
  background-image: linear-gradient(90deg, #2e76be, #5a60ad);
}
.imageBtn{
  cursor: pointer;
}